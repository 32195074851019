import { NgModule } from '@angular/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TrendModule } from 'ngx-trend';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { PageTitleComponent } from './Layout/Components/page-title/page-title.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AgmCoreModule } from '@agm/core';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { SeleccionarDireccionComponent } from './_modals/seleccionar-direccion.component';
import { SeleccionarImagenComponent } from './_modals/seleccionar-imagen.component';

import {MatCheckboxModule, MatRippleModule} from '@angular/material';
import {MatRadioModule} from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateService} from '@ngx-translate/core';

import {OptionsDrawerComponent} from './ThemeOptions/options-drawer/options-drawer.component';

import { ChartsModule } from 'ng2-charts';

const config: SocketIoConfig = { url: 'https://codigeek.app/', options: {
  'reconnection delay': 1000,
	'reconnection limit': 1000,
	'max reconnection attempts': 'Infinity',
	'path' : '/rivasgrill/socket.io'
} };

@NgModule({
 declarations: [ PageTitleComponent, SeleccionarDireccionComponent, SeleccionarImagenComponent, OptionsDrawerComponent ],
 imports: [
   AngularFontAwesomeModule,
   PerfectScrollbarModule,
   TrendModule,
   SelectDropDownModule,
   NgSelectModule,
   FormsModule,
   ReactiveFormsModule,
   NgbModule,
   ImageCropperModule,
   JwBootstrapSwitchNg2Module,
   AgmCoreModule.forRoot({
     // please get your own API key here:
     // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
     apiKey: 'AIzaSyD8gOoDYkNxX966vjCQzNAIH2ZHKpkBql4',
     libraries: ["places"]
   }),
   MatCheckboxModule,
   MatRadioModule,
   MatTabsModule,
	 SocketIoModule.forRoot(config),
   TranslateModule.forRoot({
     loader: {
       provide: TranslateLoader,
       useFactory: HttpLoaderFactory,
       deps: [HttpClient]
     }
   }),
   ChartsModule
 ],
 exports: [
   AngularFontAwesomeModule,
   PerfectScrollbarModule,
   TrendModule,
   SelectDropDownModule,
   NgSelectModule,
   FormsModule,
   ReactiveFormsModule,
   NgbModule,
   ImageCropperModule,
   JwBootstrapSwitchNg2Module,
   AgmCoreModule,
   MatCheckboxModule,
   MatRadioModule,
   MatTabsModule,
   SocketIoModule,

   PageTitleComponent,
   SeleccionarDireccionComponent,
   SeleccionarImagenComponent,
   OptionsDrawerComponent,

   TranslateModule,
   ChartsModule
 ],
 providers: [
   TranslateService
 ]
})
export class SharedModule { }


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
