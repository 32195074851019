


export class Imagen {

  public _id : String;
  public foto: String;

  constructor( _id : string = '', foto : string = '' ) {

    this._id          = _id;
    this.foto    = foto;

  }
}
