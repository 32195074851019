import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, RouterStateSnapshot } from '@angular/router';
import { DataService } from '../data.service';
import { map } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';

import { User } from '../_models/user.model';
import { Pedido } from '../_models/pedido.model';
import { Categoria } from '../_models/categoria.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
      // private currentPedidoSubject: BehaviorSubject<Pedido>;
    public currentPedido = new Pedido();
    public currentCategoria = new Categoria();
    public registration_id = "";
    public languagesArray$ = [];
    public language$ = new BehaviorSubject('');
    public currentLanguage = this.language$.asObservable();

    constructor( private socket: Socket, private dataService : DataService, private router: Router, private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser_RivasGrill')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    setLanguages( langs_t ){
      this.languagesArray$ = langs_t;
    }

    getLanguages(){
      return this.languagesArray$;
    }

    setCurrentLanguage( registro_t ){
      this.language$.next(registro_t)
    }

    getCurrentLanguage(){
      return this.currentLanguage;
    }

    setRegistrationID( reg_t ){
      this.registration_id = reg_t;
       // this.currentPedidoSubject.next(pedido_t);
    }

    getRegistrationID(){
      return this.registration_id;
    }

    setCategoria( reg_t ){
      this.currentCategoria = reg_t;
       // this.currentPedidoSubject.next(pedido_t);
    }

    setPedido( pedido_t ){
      this.currentPedido = pedido_t;
       // this.currentPedidoSubject.next(pedido_t);
    }

    getPedido( pedido_t ){
      return this.currentPedido;
       // this.currentPedidoSubject.next(pedido_t);
    }

    clearPedido(){
      this.currentPedido = new Pedido();
      // this.currentPedidoSubject.next(null);
    }

    public get currentCategoriaValue(): Categoria {
        return this.currentCategoria;
    }

    public get currentPedidoValue(): Pedido {
        return this.currentPedido;
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    getIpData(){
      this.dataService.useLink("https://api.ipgeolocation.io/ipgeo?apiKey=f128ae37c4b149f085df339dbd7f752a").subscribe(data => this.saveUserLocation( data ));
    }

    saveUserLocation(geo_info){
      this.dataService.useService("actualizar_ubicacion_usuario", { data: geo_info, usuario : this.currentUser })
      .subscribe(function (data) {
      }, function (error) {
      });
    }

    setUser(user){

      if( user ){

        this.currentUser = user;
        this.currentUserSubject.next(user);

        this.socket.emit('join', this.currentUser);

        if( this.registration_id != "" ){
          this.dataService.useService("actualizar_registration_id", { data: { "_id" : user._id, "registrationId" : this.registration_id } })
              .subscribe(function (data) {
          }, function (error) {
          });
        }

        localStorage.setItem('currentUser_RivasGrill', JSON.stringify(user));

        this.getIpData();

        switch( user.tipo_usuario_id ){
          //Admin App
          case "5c4050f358209844a83c8622":
            this.router.navigate(['administracion/dashboard'], {  });
          break;
          //Admin Neg
          case "5c4050fa58209844a83c8623":
            this.router.navigate(['pedidos/listado'], {  });
          break;
          //Repartidor
          case "5c40513658209844a83c862a":
            this.router.navigate(['pedidos/listado'], {  });
          break;
          //Cliente
          case "5c40513258209844a83c8629":
            this.router.navigate(['cliente/perfil-restaurante'], {  });
          break;
          //Restaurante
          case "5e30cadaec6ea3c622235f99":
            this.router.navigate(['pedidos/listado'], {  });
          break;
          //Place
          case "5e30cadfec6ea3c622235f9a":
            this.router.navigate(['cliente/perfil-restaurante'], {  });
          break;
          //Station
          case "5e30cae5ec6ea3c622235f9b":
            this.router.navigate(['pedidos/listado'], {  });
          break;
        }
      }else{

        this.router.navigate(['cliente/perfil-restaurante'], {  });

      }

    }

    login(username: string, password: string) {
        return this.http.post<any>('https://codigeek.app/orlandoadmin/autenticacion', { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        this.dataService.desconectarSocket();
        this.dataService.useService("cerrar_sesion", { data : this.currentUser  })
            .subscribe(function (data) {
        }, function (error) {
        });
        localStorage.removeItem('currentUser_RivasGrill');
        this.currentUserSubject.next(null);
        this.router.navigate(['pages/login'], {  });
    }
}
