import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { User } from './_models/user.model';
import { Module } from './module.model';
import { DataService } from './data.service';
import { AuthenticationService } from './_services/authentication.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {

  users$: User[];
  modules$: Module[];
  currentUser$: any;
  title = 'VieneViene';

  constructor(
     private authenticationService: AuthenticationService,
     private router: Router,
     private dataService : DataService
   ){ }

  ngOnInit(){
    this.currentUser$ = this.authenticationService.currentUserValue;
    console.log("Init app component");
    console.log(this.currentUser$);

    var registration_id_t = "";
    this.authenticationService.setUser(this.currentUser$);

    // try{
    //   console.log(PushNotification);
    //   let push = PushNotification.init({
    //       android: {
    //           senderID: "100470118971",
    //           icon: 'logo.png',
    //           iconColor: '#f9d001'
    //       },
    //       ios: {
    //           alert: "true",
    //           badge: false,
    //           sound: "true"
    //       },
    //       windows: {}
    //   });
    //   push.on('registration', data => {
    //
    //     console.log("registrationID----PUSHNOTIFICATION");
    //     console.log(data.registrationId);
    //     console.log(this.currentUser$);
    //
    //     this.authenticationService.setRegistrationID(data.registrationId);
    //
    //     if (this.currentUser$) {
    //         if (this.currentUser$._id) {
    //             this.currentUser$.registrationId = data.registrationId;
    //             this.dataService.useService("actualizar_registration_id", { data: this.currentUser$ })
    //                 .subscribe(function (data) {
    //             }, function (error) {
    //             });
    //         }
    //     }
    //   });
    //
    //   push.on('notification', data => {
    //     console.log("not recibida");
    //     var propValue;
    //     for(var propName in data) {
    //         propValue = data[propName]
    //
    //         console.log(propName,propValue);
    //     }
    //   });
    // }
    // catch(err) {
    //   console.log(err);
    // }
  }

  logout() {
      this.authenticationService.logout();
      this.router.navigate(['pages/login']);
  }

}
