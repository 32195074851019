import {Component, HostListener, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../theme-options';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import { Router, ActivatedRoute} from '@angular/router';
import { Module } from '../../../module.model';
import { Usuario } from '../../../_models/usuario.model';
import { DataService } from '../../../data.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MapsAPILoader } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';
declare let google: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  modules$: Module[];
  whatsapp$ : any;
  currentUser$ = new Usuario();


  constructor( private translate: TranslateService, private mapsAPILoader: MapsAPILoader, public globals: ThemeOptions, private router: Router, private activatedRoute: ActivatedRoute, private dataService : DataService, private authenticationService: AuthenticationService) {

  }

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  irA( url_T ){
    this.toggleSidebarMobile();
    this.router.navigateByUrl( url_T , {  });
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  userLogout(){
    this.authenticationService.logout();
  }

  getWhatsapp(){
    this.dataService.useService( "get_whatsapp" , {  } )
    .subscribe
      (
          (data : any) =>   {
            this.whatsapp$ = data.data[0].telefono;
          },
          error =>  {
            this.dataService.generalAlert(error);
          }
    );
  }

  executeTranslation( language_t ){
    //Translations
    this.translate.setDefaultLang(language_t);
    this.translate.use(language_t);
  }

  ngOnInit() {
    this.authenticationService.currentLanguage.subscribe(data => this.executeTranslation( data ));

    this.currentUser$ = this.authenticationService.currentUserValue;
    if( !this.currentUser$ ){
      this.currentUser$ = new Usuario();
    }

    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.getWhatsapp();

    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }
}
