import { ElementRef, Component, OnInit, NgZone, ViewChild, TemplateRef,  HostBinding } from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {ThemeOptions} from '../../../theme-options';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../../_services/authentication.service';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Pedido } from '../../../_models/pedido.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  pedidoActual$ = new Pedido();
  languages$ : any;
  modalReference : any;
  currentLanguage$ = "";

  @ViewChild('mLanguages') mLanguages: TemplateRef<any>;

  constructor( private ngZone: NgZone, private router: Router, public globals: ThemeOptions, private authenticationService: AuthenticationService, private modalService: NgbModal) {
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  @select('config') public config$: Observable<any>;

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }

  openLanguages(){
    this.modalReference = this.modalService.open(this.mLanguages);
  }

  selectLanguage(language_t){
    this.currentLanguage$ = language_t;
    this.authenticationService.setCurrentLanguage(language_t)
    this.modalReference.close();
  }

  irCheckout(){
    this.pedidoActual$ = this.authenticationService.currentPedidoValue;
    if( this.pedidoActual$ ){
      if( this.pedidoActual$.platillos.length > 0 ){
        this.router.navigateByUrl('/cliente/checkout', { });
      }else{
        this.router.navigateByUrl('/cliente/perfil-restaurante', { });
      }
    }else{
      this.router.navigateByUrl('/cliente/perfil-restaurante', { });
    }
  }

  ngOnInit() {

    this.languages$ = this.authenticationService.getLanguages();
    this.authenticationService.currentLanguage.subscribe(language_t => this.currentLanguage$ = language_t);

  }

}
