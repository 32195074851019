import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {ConfigActions} from '../../ThemeOptions/store/config.actions';
import {ThemeOptions} from '../../theme-options';
import { Usuario } from '../../_models/usuario.model';
import {animate, query, style, transition, trigger} from '@angular/animations';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../../_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  animations: [

    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column'

          }),
        ]),
        query(':enter', [
          animate('600ms ease', style({opacity: 1, transform: 'translateY(0)'})),
        ]),

        query(':leave', [
          animate('600ms ease', style({opacity: 0, transform: 'translateY(-20px)'})),
         ], { optional: true })
      ]),
    ])
  ]
})

export class BaseLayoutComponent {

  @ViewChild('mPedidosPendientes') mPedidosPendientes: TemplateRef<any>;

  pedidos1$               = [];
  pedidos2$               = [];
  classNotificacion$      = "notification-new-order";
  tipoNotificacion$       = 1;
  cantidadNotificacion$   = 1;
  modalOpened$            = false;
  currentUser$            : any;
  modalReference          : any;
  ioConnection            : any;
  idInterval$             : any;

  @select('config') public config$: Observable<any>;

  constructor( private translate: TranslateService, private router: Router, private dataService : DataService, private authenticationService: AuthenticationService, private modalService: NgbModal, public globals: ThemeOptions, public configActions: ConfigActions) {
    // translate.addLangs(['en', 'es']);
    // const browserLang = translate.getBrowserLang();
    // if( browserLang === "en" || browserLang === "es" ){
    //   translate.setDefaultLang(browserLang);
    //   translate.use(browserLang);
    // }else{
    //   translate.setDefaultLang('en');
    //   translate.use('en');
    // }
    this.authenticationService.setLanguages(['en', 'es']);
    this.authenticationService.setCurrentLanguage('en');
    translate.setDefaultLang('en');
    translate.use('en');
  }

  initIoConnection(){
    this.ioConnection = this.dataService.getPedidosPendientes()
    .subscribe((data: any) => {
      this.cantidadNotificacion$  = data.data.cantidad;
      var tipo_t = data.data.tipo;
      if( tipo_t === 2 ){
        this.tipoNotificacion$  = 2;
        this.classNotificacion$ = "notification-pending-order";
      }else{
        this.tipoNotificacion$  = 1;
        this.classNotificacion$ = "notification-new-order";
      }
      if( !this.modalOpened$ ){
        this.runInterval();
        this.modalOpened$ = true;
        this.modalReference = this.modalService.open(this.mPedidosPendientes);
        this.modalReference.result.then((result) => {
          this.destroyInterval();
        }, (reason) => {
          this.destroyInterval();
          console.log("exit");
        });
      }
    });
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  runInterval(){
    this.idInterval$ = setInterval(() => {
      var audio = new Audio();
      audio.src = "https://codigeek.com/projects/rivasgrill/desk_bell_x2.mp3";
      audio.load();
      audio.play();
    }, 5000);
  }

  destroyInterval(){
    this.modalOpened$ = false;
    if (this.idInterval$) {
      clearInterval(this.idInterval$);
    }
  }

  ngOnDestroy() {
    this.modalOpened$ = false;
    this.destroyInterval();
  }

  gestionarPedido(){
    this.modalOpened$ = false;
    if (this.idInterval$) {
      clearInterval(this.idInterval$);
    }
    console.log("GestionarPedido go..");
    this.modalReference.close();
    this.router.navigateByUrl('/pedidos/listado', { });
  }

  getRegistros(tipo_t){

    console.log("-------TIPO: " + tipo_t);

    this.currentUser$ = this.authenticationService.currentUserValue;

    this.pedidos1$ = [];

    console.log("baselayout get registros");
    console.log(this.currentUser$.tipo_usuario_id);
    if( this.currentUser$.tipo_usuario_id === "5c4050f358209844a83c8622" ||
        this.currentUser$.tipo_usuario_id === "5c4050fa58209844a83c8623" ||
        this.currentUser$.tipo_usuario_id === "5e30cadaec6ea3c622235f99" ||
        this.currentUser$.tipo_usuario_id === "5e30cae5ec6ea3c622235f9b"
      ){

        if( tipo_t === 2 ){
          this.tipoNotificacion$  = 2;
          this.classNotificacion$ = "notification-pending-order";
        }else{
          this.tipoNotificacion$  = 1;
          this.classNotificacion$ = "notification-new-order";
        }
        if( !this.modalOpened$ ){
          this.runInterval();
          this.modalOpened$ = true;
          this.modalReference = this.modalService.open(this.mPedidosPendientes);
          this.modalReference.result.then((result) => {
            this.destroyInterval();
          }, (reason) => {
            this.destroyInterval();
            console.log("exit");
          });
        }

        // let servicio = "";
        // switch( this.currentUser$.tipo_usuario_id ){
        //   //Admin App
        //   case "5c4050f358209844a83c8622":
        //     servicio = "get_pedidos_administracion_pendientes";
        //   break;
        //   //Admin Neg - Place - Station - Restaurante
        //   case "5c4050fa58209844a83c8623":
        //   case "5e30cadaec6ea3c622235f99":
        //   case "5e30cadfec6ea3c622235f9a":
        //   case "5e30cae5ec6ea3c622235f9b":
        //     servicio = "get_pedidos_negocio_pendientes";
        //   break;
        //   //Repartidor
        //   case "5c40513658209844a83c862a":
        //     servicio = "get_pedidos_motofast_pendientes";
        //   break;
        //   //Cliente
        //   case "5c40513258209844a83c8629":
        //     servicio = "get_pedidos_usuario_pendientes";
        //   break;
        // }
        //
        // this.dataService.useService( servicio , { data : this.currentUser$ } )
        // .subscribe
        //   (
        //       (data : any) =>   {
        //         if( tipo_t === 2 ){
        //           this.tipoNotificacion$  = 2;
        //           this.classNotificacion$ = "notification-pending-order";
        //         }else{
        //           this.tipoNotificacion$  = 1;
        //           this.classNotificacion$ = "notification-new-order";
        //         }
        //         if( !this.modalOpened$ ){
        //           this.runInterval();
        //           this.modalOpened$ = true;
        //           this.modalReference = this.modalService.open(this.mPedidosPendientes);
        //           this.modalReference.result.then((result) => {
        //             this.destroyInterval();
        //           }, (reason) => {
        //             this.destroyInterval();
        //             console.log("exit");
        //           });
        //         }
        //       },
        //       error =>  {
        //         this.dataService.generalAlert(error);
        //       }
        // );
      }
  }

  executeTranslation( language_t ){
    //Translations
    this.translate.setDefaultLang(language_t);
    this.translate.use(language_t);
  }

  ngOnInit() {
    this.authenticationService.currentLanguage.subscribe(data => this.executeTranslation( data ));
    this.currentUser$ = this.authenticationService.currentUserValue;
    if( !this.currentUser$ ){
      this.currentUser$ = new Usuario();
    }else{
      if( this.currentUser$.tipo_usuario_id === "5c4050f358209844a83c8622" ||
          this.currentUser$.tipo_usuario_id === "5c4050fa58209844a83c8623" ||
          this.currentUser$.tipo_usuario_id === "5e30cadaec6ea3c622235f99" ||
          this.currentUser$.tipo_usuario_id === "5e30cae5ec6ea3c622235f9b"
        ){
          this.initIoConnection();
      }
    }
    setInterval(() => {
      if(!this.modalOpened$){
        if (this.idInterval$) {
          clearInterval(this.idInterval$);
        }
      }
    }, 5000);
  }
}
