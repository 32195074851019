
import { Categoria } from './categoria.model';

export class Negocio {

  public _id : String;
  public rfc: String;
  public nombre: String;
  public descripcion: String;
  public telefono: String;
  public direccion: String;
  public tipo_distancia: String;
  public external_account_id: String;
  public comision: number;
  public impuesto: number;
  public latitude: number;
  public longitude: number;
  public location: Object;
  public stripe_id: String;
  public currency: String;
  public platillos: Object;
  public categorias: Object;
  public hora_servicio1: Object;
  public hora_servicio: Object;
  public categoria: Categoria;
  public categoria_id: String;
  public banner_image: String;
  public foto: String;
  public tipo_pago: number;
  public status: number;
  public lat: number;
  public lng: number;
  public draggable: boolean;
  public source: Object;
  public source_id: String;

  constructor(
    _id : string = '',
    rfc: string = '',
    nombre: string = '',
    descripcion: string = '',
    telefono: string = '',
    direccion: string = '',
    tipo_distancia: string = 'KM',
    external_account_id: string = '',
    comision: number = 5,
    impuesto: number = 0,
    latitude: number = 0,
    longitude: number = 0,
    location: string = '',
    stripe_id: string = '',
    currency: string = '',
    platillos: string = '',
    hora_servicio1: string = '',
    hora_servicio: string = '',
    categorias: string = '',
    categoria: Categoria = new Categoria(),
    categoria_id: string = '',
    banner_image: string = '',
    foto: string = '',
    tipo_pago: number = 0,
    status: number = 1,
    lat: number = 0,
    lng: number = 0,
    draggable: boolean = false,
    source: string = '',
    source_id: string = ''
  ) {

    this._id          = _id;
    this.rfc          = rfc;
    this.nombre       = nombre;
    this.descripcion  = descripcion;
    this.telefono     = telefono;
    this.direccion    = direccion;
    this.direccion    = direccion;
    this.external_account_id = external_account_id;
    this.comision     = comision;
    this.impuesto     = impuesto;
    this.latitude     = latitude;
    this.longitude    = longitude;
    this.location     = location;
    this.stripe_id    = stripe_id;
    this.currency     = currency;
    this.platillos    = platillos;
    this.categorias   = categorias;
    this.categoria    = categoria;
    this.hora_servicio1 = hora_servicio1;
    this.hora_servicio = hora_servicio;
    this.categoria_id = categoria_id;
    this.banner_image = banner_image;
    this.foto         = foto;
    this.tipo_pago    = tipo_pago;
    this.status       = status;
    this.lat          = lat;
    this.lng          = lng;
    this.draggable    = draggable;
    this.source       = source;
    this.source_id    = source_id;

  }
}
