import {Component, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../../../theme-options';
import { AuthenticationService } from '../../../../../_services/authentication.service';
import { Usuario } from '../../../../../_models/usuario.model';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  currentUser$ : any;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(private router: Router, public globals: ThemeOptions, private authenticationService: AuthenticationService) {
  }

  irAInicio(){
    if( !this.currentUser$._id ){
      this.router.navigateByUrl('/pages/login', { });
    }
  }

  ngOnInit() {
    this.currentUser$ = this.authenticationService.currentUserValue;
    if( !this.currentUser$ ){
      this.currentUser$ = new Usuario();
    }
  }

}
