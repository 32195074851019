


export class Categoria {

  public _id : String;
  public nombre: String;
  public negocio_id: String;
  public foto: String;
  public orden: number;
  public status: number;

  constructor( _id : string = '', nombre: string = '',  negocio_id: string = '', foto: string = '', orden: number = 0, status: number = 1 ) {

    this._id          = _id;
    this.nombre       = nombre;
    this.negocio_id   = negocio_id;
    this.foto         = foto;
    this.orden        = orden;
    this.status       = status;

  }
}
