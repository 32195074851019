
import { Platillo } from './platillo.model';
import { Forma_Pago } from './forma_pago.model';
import { Negocio } from './negocio.model';

export class Pedido {

  public _id : String;
  public fecha_alta: Object;
  public origen: Object;
  public origen_direccion: String;
  public origen_latitude: number;
  public origen_longitude: number;
  public destino: Object;
  public destino_direccion: String;
  public destino_latitude: number;
  public destino_longitude: number;
  public descripcion: String;
  public referencia: String;
  public foto: String;
  public correo_cliente: String;
  public telefono: String;
  public tipo_servicio: number;
  public motofast: Object;
  public negocio: Negocio;
  public usuario: Object;
  public usuario_id: String;
  public forma_pago: Forma_Pago;
  public forma_pago_id: String;
  public tarjeta: Object;
  public tarjeta_id: String;
  public sucursal_id: String;
  public negocio_id: String;
  public motofast_id: String;
  public distancia_diferencia: number;
  public minimo: number;
  public maximo: number;
  public subtotal: number;
  public impuesto: number;
  public impuesto_total: number;
  public costo_envio: number;
  public platillos : Platillo[];
  public total: number;
  public status: number;
  public hora_servicio: Object;

  public fecha_asignar_motofast: Date;
  public fecha_recogi_productos: Date;
  public fecha_entregue_productos: Date;
  public fecha_platillos_listos: Date;
  public fecha_cancelacion: Date;
  public paid: boolean;

  constructor(
      _id : string = '',
      fecha_alta : string = '',
      origen = {
        "direccion" : '',
        "latitude" :  '',
        "longitude" : ''
      },
      origen_direccion: string = '',
      origen_latitude: number = 0,
      origen_longitude: number = 0,
      destino = {
        "direccion" : '',
        "latitude" :  '',
        "longitude" : ''
      },
      destino_direccion: string = '',
      destino_latitude: number = 0,
      destino_longitude: number = 0,
      descripcion: string = '',
      referencia: string = '',
      foto: string = '',
      correo_cliente: string = '',
      telefono: string = '',
      tipo_servicio: number = 0,
      motofast: string = '',
      negocio: Negocio = new Negocio(),
      usuario: string = '',
      usuario_id: string = '',
      forma_pago: Forma_Pago = new Forma_Pago(),
      forma_pago_id: string = '',
      tarjeta: string = '',
      tarjeta_id: string = '',
      sucursal_id: string = '',
      negocio_id: string = '',
      motofast_id: string = '',
      distancia_diferencia: number = 0,
      minimo: number = 0,
      maximo: number = 0,
      subtotal: number = 0,
      impuesto: number = 0,
      impuesto_total: number = 0,
      costo_envio: number = 0,
      platillos : Platillo[] = [],
      total: number = 0,
      status: number = 1,
      hora_servicio: string = '',


      fecha_asignar_motofast: Date = new Date(),
      fecha_recogi_productos: Date = new Date(),
      fecha_entregue_productos: Date = new Date(),
      fecha_platillos_listos: Date = new Date(),
      fecha_cancelacion: Date = new Date(),

      paid: boolean = false

  ) {

    this._id                = _id;
    this.fecha_alta         = fecha_alta;
    this.origen             = origen;
    this.origen_direccion   = origen_direccion;
    this.origen_latitude    = origen_latitude;
    this.origen_longitude   = origen_longitude;
    this.destino            = destino;
    this.destino_direccion  = destino_direccion;
    this.destino_latitude   = destino_latitude;
    this.destino_longitude  = destino_longitude;
    this.descripcion        = descripcion;
    this.referencia         = referencia;
    this.foto               = foto;
    this.correo_cliente     = correo_cliente;
    this.telefono           = telefono;
    this.tipo_servicio      = tipo_servicio;
    this.motofast           = motofast;
    this.negocio            = negocio;
    this.usuario            = usuario;
    this.usuario_id         = usuario_id;
    this.forma_pago         = forma_pago;
    this.forma_pago_id      = forma_pago_id;
    this.tarjeta            = tarjeta;
    this.tarjeta_id         = tarjeta_id;
    this.sucursal_id        = sucursal_id;
    this.negocio_id         = negocio_id;
    this.motofast_id        = motofast_id;
    this.distancia_diferencia = distancia_diferencia;
    this.minimo             = minimo;
    this.maximo             = maximo;
    this.subtotal           = subtotal;
    this.total              = total;
    this.impuesto           = impuesto;
    this.impuesto_total     = impuesto_total;
    this.costo_envio        = costo_envio;
    this.platillos          = platillos;

    this.hora_servicio      = hora_servicio;

    this.fecha_asignar_motofast = fecha_asignar_motofast;
    this.fecha_recogi_productos = fecha_recogi_productos;
    this.fecha_entregue_productos = fecha_entregue_productos;
    this.fecha_platillos_listos = fecha_platillos_listos;
    this.fecha_cancelacion = fecha_cancelacion;
    this.paid = paid;
  }
}
