import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Module } from './module.model';
import Swal from 'sweetalert2'
import { Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  apiURL = "https://codigeek.app/rivasgrill/";
  language$ = "";

  constructor( private translate: TranslateService, private socket: Socket, private router: Router, private _http : HttpClient) { }

  public getPedidosPendientes(): Observable<any> {
      return new Observable<any>(observer => {
          this.socket.on('actualizar_pedidos_pendientes', (data: any) => observer.next(data));
      });
  }

  generalAlert( payload$ ){
    (Swal as any).fire({
      type: payload$.status,
      title: payload$.message,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000
    })
  }

  desconectarSocket(){
    // this.socket.disconnect();
  }

  conectarSocket(){
    // console.log("conectarSocket----------------------------------");
    // this.socket.on('connect', () => {
    //   console.log('Successfully connected!');
    // });
  }

  useLink( url ){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      var test_t = this._http.get(url);
      return test_t;
  }

  useService( url, payload$ ){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      var test_t = this._http.post(this.apiURL+url, payload$, httpOptions);
      return test_t;
  }

  autenticacion(usuarioP){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      var test_t = this._http.post(this.apiURL+"autenticacion", { data : usuarioP }, httpOptions);
      return test_t;
  }

  getModules(){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      var test_t = this._http.post(this.apiURL+"get_modules_tree", [], httpOptions);
      return test_t;
  }

  getUsuarios(){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      var test_t = this._http.post(this.apiURL+"get_usuarios", [], httpOptions);
      return test_t;
  }

  getAccesos(){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      var test_t = this._http.post(this.apiURL+"get_accesos", [], httpOptions);
      return test_t;
  }

}
